import { IBaseConfig } from "@/ioc/types";
import axios from "axios";
import { ListDataSource } from "./ListDataSource";
import {toQueryString} from "@/cms-services/helpers";

export class InvitationListDataSource extends ListDataSource {
  constructor(config: IBaseConfig) {
    super({
      className: "invitation",
      config,
    });
  }



  public async downloadXlsx() {
    try {
      const { data } = await axios.get(
        `${this.baseUrl}/download/xlsx/`,
        {
          responseType: "blob",
        },
      );
      const url = window.URL.createObjectURL(data);
      var link = document.createElement("a");
      link.download = "Статистика.xlsx";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return data;
    } catch (err) {
      throw err;
    }
  }

  public async invite(id: number) {
    try {
      await axios.post(`${this.baseUrl}/${id}/invite`);
    } catch (error) {
      throw error;
    }
  }
}
